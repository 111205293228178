<template>
  <section class="section">
    <div class="section-header">
      <h1>{{ $route.name }}</h1>
    </div>
    <div class="row mt-sm-4">
      <div class="col-12 col-md-12 col-lg-5">
        <div class="card profile-widget">
          <div class="profile-widget-header">
            <img
              alt="image"
              src="assets/img/avatar/avatar-1.png"
              class="rounded-circle profile-widget-picture"
            />
            <div class="profile-widget-items">
              <div class="profile-widget-item">
                <div class="profile-widget-item-label">Priority</div>
                <div class="profile-widget-item-value">{{ task.priority }}</div>
              </div>
              <div class="profile-widget-item">
                <div class="profile-widget-item-label">Status</div>
                <div class="profile-widget-item-value">{{ task.status }}</div>
              </div>
              <div class="profile-widget-item">
                <div class="profile-widget-item-label">Completed</div>
                <div class="profile-widget-item-value">
                  {{ `${task && countCompleted(task.checklist)}/${task.checklist.length}` }}
                </div>
              </div>
            </div>
          </div>
          <div class="profile-widget-description">
            <div class="profile-widget-name">
              {{ task.title }}
              <div class="text-muted d-inline font-weight-normal">
                <div class="slash"></div>
                {{ task.assign_to && task.assign_to.name }}
              </div>
            </div>
            {{ task.description }}
            <div class="form-group mt-3 mb-3">
              <label>Update Status</label>
              <select
                class="form-control select2"
                v-model="selectedStatus"
                @change="updateTask"
                :disabled="task.status == 'completed'"
              >
                <option value="open">Not completed</option>
                <option value="completed">Completed</option>
              </select>
            </div>
          </div>
          <div class="card-footer text-center" v-if="task.file">
            <div class="font-weight-bold mb-2">Download Attachment</div>
            <a
              :href="task.file"
              target="_blank"
              class="btn btn-social-icon btn-facebook mr-1"
              style="background: #000"
            >
              <i class="fa fa-download"></i>
              Download
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12 col-lg-7">
        <div class="card">
          <div
            class="table-responsive"
            v-if="task.checklist && task.checklist.length > 0"
          >
            <table class="table table-striped">
              <tr>
                <th class="text-center pt-2"></th>
                <th>Description</th>
                <th>Status</th>
              </tr>
              <tr v-for="(item, index) in task.checklist" :key="index">
                <td>
                  <div class="custom-checkbox custom-control">
                    <input
                      type="checkbox"
                      data-checkboxes="mygroup"
                      class="custom-control-input"
                      :id="`checkbox-${index}`"
                      :checked="item.status == 'closed'"
                      :disabled="item.status == 'closed'"
                      @click.prevent="updateTaskChecklist(item.id)"
                    />
                    <label
                      :for="`checkbox-${index}`"
                      class="custom-control-label"
                      >&nbsp;</label
                    >
                  </div>
                </td>
                <td
                  :class="item.status == 'closed' ? 'text-strikethrough' : ''"
                >
                  {{ item.title }}
                </td>
                <td>
                  <div
                    class="badge"
                    :class="[
                      item.status == 'open' ? 'badge-danger' : 'badge-primary',
                    ]"
                  >
                    {{ item.status == "open" ? "Not completed" : "Completed" }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div v-else>
            <h6 class="text-center mt-3 mb-3">No checklist for this Todo</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import TaskHelpers from "@/functions/actions/TaskRequest";
import { mapState } from "vuex";
export default {
  name: "ViewTask",
  computed: {
    ...mapState(["loading"]),
  },
  props: {
    taskId: {
      required: true,
      type: [String, Number],
    },
  },
  mounted() {
    this.singleTask();
  },
  data() {
    return {
      task: [],
      selectedStatus: "",
    };
  },
  methods: {
    countCompleted(arr) {
      let sum = 0
      arr.forEach(element => {
        if (element.status == 'closed') {
          sum += 1
        }
      });
      return sum
    },
    async singleTask() {
      const response = await TaskHelpers.singleTask(this.taskId);
      if (response.status) {
        this.task = response.task;
        this.selectedStatus = this.task.status;
      } else {
        this.router.go(-1);
        this.showAlert("Error occured", `${response.message}`, "error");
      }
    },
    async updateTask() {
      this.$confirm("Are you sure? You can't undo this").then(async () => {
        const response = await TaskHelpers.updateTaskStatus(
          this.taskId,
          this.selectedStatus
        );
        if (response.status) {
          this.task.status = this.selectedStatus;
        } else {
          this.selectedStatus = this.task.status;
          this.showAlert("Error occured", `${response.message}`, "error");
        }
      });
    },
    async updateTaskChecklist(checklistId) {
      const checklist = this.task.checklist.find(
        (item) => item.id == checklistId
      );
      const status = checklist.status == "open" ? "closed" : "open";
      this.$confirm("Are you sure? You can't undo this").then(async () => {
        const response = await TaskHelpers.updateTaskChecklistStatus(
          this.taskId,
          checklistId,
          status
        );
        if (response.status) {
          checklist.status = status;
        } else {
          this.showAlert("Error occured", `${response.message}`, "error");
        }
      });
    },
    showAlert(title, text, type) {
      this.$fire({
        title,
        text,
        type: type ? type : "warning",
      });
    },
  },
};
</script>
<style scoped>
.text-strikethrough {
  text-decoration: line-through;
}
</style>