import store from "../../store";
import router from "../../router";
import TaskService from "../../services/TaskService";

export default {
  getTasks() {
    store.dispatch("setLoading", true);
    const response = TaskService.getTasks()
      .then((result) => {
        const { status, data: { tasks } } = result.data
        if (status) {
          return { status: true, tasks };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  singleTask(taskId) {
    store.dispatch("setLoading", true);
    const response = TaskService.singleTask(taskId)
      .then((result) => {
        const { status, data: { task } } = result.data
        if (status) {
          return { status: true, task };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  updateTaskStatus(taskId, status) {
    store.dispatch("setLoading", true);
    const response = TaskService.updateTaskStatus(taskId, status)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  updateTaskChecklistStatus(taskId, checklistId, status) {
    store.dispatch("setLoading", true);
    const response = TaskService.updateTaskChecklistStatus(taskId, checklistId, status)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
  createTask(payload) {
    store.dispatch("setLoading", true);
    const response = TaskService.createTask(payload)
      .then((result) => {
        const { status } = result.data
        if (status) {
          return { status: true };
        } else {
          return { status: false }
        }
      })
      .catch((err) => {
        if (err?.response === undefined) {
          return { status: false, message: 'Oops! took long to get a response' }
        } else {
          const { status, data } = err.response
          if (status === 401) {
            store.dispatch("logout");
            router.push({
              name: "Login",
            });
          } else {
            return { status: false, message: data?.message }
          }
        }
      })
      .finally(() => {
        store.dispatch("setLoading", false);
      });
      return response
  },
};
