import Api from "@/services/Api";

export default {
  getTasks() {
    return Api().get("/support/tasks");
  },
  singleTask(taskId) {
    return Api().get(`/support/tasks/${taskId}`);
  },
  updateTaskStatus(taskId, status) {
    return Api().put(`/support/tasks/${taskId}/status`, { status });
  },
  updateTaskChecklistStatus(taskId, checklistId, status) {
    return Api().post(`/support/tasks/${taskId}/checklist/${checklistId}`, { status });
  },
  addComment(taskId, comment) {
    return Api().post(`/support/tasks/${taskId}/comment`, { comment });
  },
  createTask(credentials) {
    return Api().post("/support/tasks", credentials);
  },
};